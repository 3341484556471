import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        This is a drone-themed version of the word guessing game we all know and
        love - brought to you by the team at {' '}
        <a
          href="https://dronedesk.io"
          className="underline font-bold"
        >
          dronedesk.io
        </a>{' '}
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300 mt-10">
        It's built using the open source version of the game created by Chase Wackerfuss and available {' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
          here
        </a>{' '} 
      </p>
    </BaseModal>
  )
}
